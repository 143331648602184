import React from 'react'
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Card from '../components/link_card'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneSquareAlt} from "@fortawesome/pro-solid-svg-icons";
import SEO from "../components/seo";

const ServiceLink = ({name, url}) => (
  <Link to={url}
        className="block py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
        activeClassName={"border-b-2 border-indigo-600 font-black"}
  >{name}</Link>
)

const PublicServices = ({ data }) => {
  const links = data.allMdx.edges;
  
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Public Services" />
      <div className="flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 order-last md:order-none">
          <div className="flex-shrink-0 py-4 flex flex-row items-center justify-between md:pr-6 md:border-r-1 md:border-gray-200 md:fixed md:w-64 md:order-last">
            <nav className="flex-grow md:block pb-4 md:pb-0 md:overflow-y-auto flex-wrap hidden">
              {links.map(({ node }) => (
                <ServiceLink name={node.frontmatter.title} url={node.frontmatter.path} key={node.id} />
              ))}
            </nav>
          </div>
        </div>
        <div className="flex flex-col w-full md:pl-4 mb-8">
          <div className="markdown-body mt-6">
            <h1>Public Services</h1>
          </div>
          <div className="flex-shrink-0 py-0 flex flex-col md:flex-row mt-4">
            <div className="markdown-body w-full md:w-1/2 mb-4 md:mb-0">
              <p>
                The Parish of <span className="font-black">Burton upon Stather</span> with Thealby and Normanby has a
                number of local services. The doctor’s surgery is connected with Winterton Medical Practice and has
                appointments Monday to Friday. There is a mobile library that visits every Tuesday and a community post
                office is held in the vestry of St Andrews Church three mornings a week. Burton upon Stather has a
                village primary school.
              </p>
            </div>
            <div className="inline-block w-full md:w-1/2 pl-6 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse">
              <h2 className={'text-2xl font-bold my-4'} style={{ lineHeight: '1.43' }}>
                Important Contact Numbers
              </h2>
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <b>Emergency:</b>{' '}
                  <a href="tel:999">
                    <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> : 999
                  </a>
                </li>
                <li>
                  <b>Police Non-emergency:</b>{' '}
                  <a href="tel:101">
                    <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> : 101
                  </a>
                </li>
                <li>
                  <b>NHS 111 Service:</b>{' '}
                  <a href="tel:111">
                    <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> : 111
                  </a>
                </li>
                <li>
                  <b>Burton Surgery:</b>{' '}
                  <a href="tel:+441724720202">
                    <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> : 01724 720202
                  </a>
                </li>
                <li>
                  <b>Winterton Surgery:</b>{' '}
                  <a href="tel:+441724732202">
                    <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> : 01724 732202
                  </a>
                </li>
                <li>
                  <b>North Lincs:</b>{' '}
                  <a href="tel:+441724297000">
                    <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> : 01724 297000
                  </a>
                </li>
                <li>
                  <b>Parish Clerk:</b>{' '}
                  <a href="tel:+447982265914">
                    <FontAwesomeIcon icon={faPhoneSquareAlt} size="sm" /> : 07982 265914
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr className="mb-4 mt-6 border-gray-500" style={{ color: '#eee' }} />
          <div className="flex flex-wrap -mx-1 lg:-mx-4">
            {links.map(({ node }) => (
              <Card
                name={node.frontmatter.title}
                url={node.frontmatter.path}
                image={node.frontmatter.featuredImage?.childImageSharp.gatsbyImageData.images.fallback.src}
                key={node.id}
                alt={node.frontmatter?.imageAltText}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PublicServicesQuery {
    allMdx(
      filter: {internal: {contentFilePath: { regex: "/content/public_services/" } }}
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            imageAltText
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          id
        }
      }
    }
  }
`

export default PublicServices

